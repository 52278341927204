.editor {
  display: flex;
  height: 100%;
  flex: 1;
}

.editor-menu {
  width: 250px;
  background-color: gray;
}

.survey-board {
  flex: 1;
  background-color: #e0e0e0;
}
.survey-header {
  padding: 15px;
  border-radius: 8px;
  display: flex;
}
.survey-header > div:first-child {
  flex: 1;
}
.tab-scroll {
  height: calc(100vh - 143px);
  overflow: auto;
}

.survey {
  flex: 1;
  width: auto !important;
}

.page-responsive {
  padding: 15px;
}
@media only screen and (max-width: 600px) {
  .page-responsive {
    padding: 0;
    padding-top: 15px;
  }
}
