html {
  background-color: #e0e0e0;
}
* {
  font-family: "Poppins";
}

h1 {
  margin-top: 0;
}

.App {
}

.login-container {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 400px;
}

.App-logo {
  height: 55px;
  pointer-events: none;
  border-radius: 100%;
  margin: -5px 5px;
}

.App-header {
  background-color: #2196f3;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  color: white;
}

.App-header > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.App-main {
}

.App-header p {
  margin: 0;
}

.App-link {
  color: #61dafb;
}

.flex-lg-col {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.flex-lg-row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.image-selected {
  position: absolute;
  left: 10px;
  top: 10px;
  background-color: white;
  border-radius: 100%;
}

.survey-list-menu {
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.survey-list {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
}

.survey-list > div {
  display: flex;
  flex: 1;
  gap: 40px;
}
.survey-list > div > div {
  flex: 1;
}

.logout {
  cursor: pointer;
  padding: 5px 10px;
  background: #ef5350;
  border-radius: 5px;
}
.logout:hover {
  background: #c62828;
}

/* override */
.MuiColorInput-PopoverBody {
  background-color: gray !important;
}
